/* CSS Reset */
* {
  box-sizing: border-box;
}

body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  transition: background-color 0.5s, color 0.5s;
}

/* Optional: Smooth font rendering */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  background: linear-gradient(to bottom left, var(--background-dark-start), var(--background-dark-end));
  min-height: 100vh;
}
