.bored-grid-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.bored-grid-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.bored-grid {
  display: grid;
  gap: 5px;
  justify-content: center;
  max-width: 100%;
  overflow-x: auto;
}

.bored-checkbox-item {
  width: 30px;
  height: 30px;
  position: relative;
}

.bored-checkbox-label {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.bored-custom-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.bored-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--secondary-color);
  border-radius: 4px;
  transition: background-color 0.3s, box-shadow 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bored-checkbox-label:hover .bored-checkmark {
  background-color: var(--hover-color);
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
}

.bored-custom-checkbox:checked + .bored-checkmark {
  background-color: var(--primary-color);
  box-shadow: 0 0 8px rgba(76, 175, 80, 0.6);
}

.bored-x-mark {
  display: none;
  color: white;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
}

.bored-custom-checkbox:checked + .bored-checkmark .bored-x-mark {
  display: block;
}

.bored-grid-loading,
.bored-grid-error {
  text-align: center;
  margin-top: 20px;
  font-size: 1.2rem;
}

.bored-grid-error {
  color: #ff4d4d;
}