/* Root Variables for Consistency */
:root {
  --primary-color: #4caf50;      /* Green for checked state */
  --secondary-color: #3a3a3a;    /* Dark gray for unchecked state */
  --hover-color: #4a4a4a;        /* Slightly lighter gray on hover */
  --background-dark-start: #1e1e1e;  /* Dark gray for gradient start */
  --background-dark-end: #1a2a3a;    /* Grayish dark blue for gradient end */
  --background-light-start: #f5f5f5; /* Light gray for gradient start in light mode */
  --background-light-end: #e0e5f0;   /* Light grayish blue for gradient end in light mode */
  --text-dark: #ffffff;          /* White text for dark mode */
  --text-light: #333333;         /* Dark text for light mode */
  --gap-size: 15px;              /* Uniform gap between checkboxes */
  --checkbox-size: 50px;         /* Size of each checkbox */
  --button-bg: #4caf50;          /* Button background color */
  --button-text: #ffffff;        /* Button text color */
  --button-hover: #45a049;       /* Button hover color */
}

/* Container for the entire grid and title */
.checkbox-grid-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  min-height: 100vh;
  background: linear-gradient(to bottom left, var(--background-dark-start), var(--background-dark-end));
  color: var(--text-dark);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  transition: background 0.5s, color 0.5s;
}

/* Dark Mode Styles */
.checkbox-grid-container.dark-mode {
  background: linear-gradient(to bottom left, var(--background-dark-start), var(--background-dark-end));
  color: var(--text-dark);
}

/* Light Mode Styles */
.checkbox-grid-container.light-mode {
  background: linear-gradient(to bottom left, var(--background-light-start), var(--background-light-end));
  color: var(--text-light);
}

/* Header with Title and Buttons */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: inherit;
}

.title {
  font-size: 2rem;
  letter-spacing: 2px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  margin: 0;
  user-select: none;  /* Prevent text selection */
  cursor: default;    /* Use default cursor */
}

/* Prevent text selection and editing for all elements */
* {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

/* Ensure no elements are unintentionally editable */
* {
  -webkit-user-modify: read-only;
  -moz-user-modify: read-only;
  -user-modify: read-only;
}

/* Header Right Section */
.header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Button Styling */
.toggle-button,
.connect-button,
.disconnect-button,
.network-button {
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  letter-spacing: 1px;
}

.toggle-button:hover,
.connect-button:hover,
.disconnect-button:hover,
.network-button:hover {
  background-color: var(--button-hover);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.toggle-button:active,
.connect-button:active,
.disconnect-button:active,
.network-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.network-button.wrong-network {
  background-color: #ff4d4d;
  color: white;
}

.network-button.wrong-network:hover {
  background-color: #ff3333;
}

/* Address Display */
.address-display {
  background-color: var(--secondary-color);
  color: var(--text-dark);
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.address-display:hover {
  background-color: var(--hover-color);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

/* Grid Layout */
.checkbox-grid {
  display: grid;
  grid-template-columns: repeat(6, var(--checkbox-size));
  grid-template-rows: repeat(6, var(--checkbox-size));
  gap: var(--gap-size);
  justify-content: center;
  align-content: center;
  margin-top: 30px;
}

/* Each Checkbox Item */
.checkbox-item {
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  position: relative;
}

/* Custom Checkbox Label */
.checkbox-label {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
}

/* Hide the default checkbox */
.custom-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

/* Custom 'X' Indicator */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--secondary-color);
  border-radius: 10px;
  transition: background-color 0.3s, box-shadow 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Hover Effect */
.checkbox-label:hover .checkmark {
  background-color: var(--hover-color);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
}

/* When the checkbox is checked, change the background */
.custom-checkbox:checked + .checkmark {
  background-color: var(--primary-color);
  box-shadow: 0 0 15px rgba(76, 175, 80, 0.6);
}

/* X Mark */
.x-mark {
  display: none;
  color: white;
  font-size: calc(var(--checkbox-size) * 0.6);
  font-weight: bold;
  line-height: 1;
}

/* Show the 'X' when checked */
.custom-checkbox:checked + .checkmark .x-mark {
  display: block;
}

/* Status Messages */
.status-message {
  font-size: 1.2rem;
  margin-top: 50px;
  text-align: center;
}

.status-message.error {
  color: #ff4d4d;
}

/* Stats Container */
.stats-container {
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.1em;
}

.stats-container p {
  margin: 5px 0;
}

/* Responsive Design */
@media (max-width: 800px) {
  :root {
    --checkbox-size: 40px;
    --gap-size: 10px;
  }

  .title {
    font-size: 1.5rem;
  }

  .toggle-button,
  .connect-button,
  .disconnect-button,
  .network-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

@media (max-width: 500px) {
  :root {
    --checkbox-size: 30px;
    --gap-size: 8px;
  }

  .title {
    font-size: 1.2rem;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-right {
    margin-top: 10px;
  }

  .toggle-button,
  .connect-button,
  .disconnect-button,
  .network-button {
    font-size: 0.8rem;
    padding: 6px 12px;
  }
}

/* Loading State for Checkboxes */
.checkmark.loading {
  background-color: rgba(58, 58, 58, 0.5); /* Semi-transparent version of --secondary-color */
  animation: pulse 1.5s infinite alternate;
}

@keyframes pulse {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

/* Disable hover effects while loading */
.checkbox-label:hover .checkmark.loading {
  background-color: rgba(58, 58, 58, 0.5);
  box-shadow: none;
}

/* New Block Info Styling with Fixed Width */
.block-info {
  width: 220px; /* Adjust the width as needed */
  min-width: 210px;
  max-width: 210px;
  margin-left: 15px;
  font-size: 1rem;
  color: var(--text-light);
  display: flex;
  align-items: center;
  justify-content: center; /* Center the text horizontally */
  text-align: center;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide any overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  /* Optional: Add a border or background for better visibility */
  /* border: 1px solid var(--secondary-color); */
  /* border-radius: 10px; */
}

.checkbox-grid-container.dark-mode .block-info {
  color: var(--text-dark);
}

.checkbox-grid-container.light-mode .block-info {
  color: var(--text-light);
}

/* Optional: Adjust spacing and responsiveness */
@media (max-width: 800px) {
  .block-info {
    width: 210px; /* Adjust for smaller screens */
    min-width: 210px;
    max-width: 210px;
    font-size: 0.9rem;
    margin-left: 10px;
  }
}

@media (max-width: 500px) {
  .block-info {
    width: 120px; /* Further adjust for very small screens */
    min-width: 120px;
    max-width: 120px;
    font-size: 0.8rem;
    margin-left: 5px;
  }
}

/* Ensure .header-right uses flex properties properly */
.header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}