/* Remove default margins and paddings */
body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Optional: Smooth font rendering */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
